import * as React from "react";

import {
    ClickAwayListener,
    Grid,
    Grow,
    IconButton,
    makeStyles, MenuItem,
    MenuList,
    Paper,
    Popper,
    Theme,
    Toolbar
} from "@material-ui/core";
import {StyledIcon} from "../../components";

import {configuration} from "../../../configuration";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        position: "absolute",
        top: 0,
        zIndex: theme.zIndex.drawer + 1,
        width: "100vw",
        height: configuration.layout.toolbarHeight,
    },
    menu: {
        width: "80vw",
        padding: theme.spacing(0, 2),
    },
    menuItem: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.divider,

        "&:last-child": {
            borderBottomWidth: 0,
        }
    }
}));

const Header = () => {

    const classes = useStyles();

    const {t,} = useTranslation();

    const [openMenu, setOpenMenu] = React.useState<boolean>(false);

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    /**
     * On open menu.
     */
    const handleToggle = () => {
        setOpenMenu(!openMenu);
    };

    /**
     * On close menu.
     * @param event
     */
    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenMenu(false);
    };

    return (
        <Toolbar disableGutters={true} className={classes.appBar}>
            <Grid container={true} direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item={true}>

                    <IconButton ref={anchorRef}
                                aria-controls={openMenu ? "menu-list-grow" : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}>
                        <StyledIcon className="mdi mdi-menu"/>
                    </IconButton>

                    <Popper open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{transformOrigin: placement === "bottom" ? "center top" : "center bottom"}}>
                                <Paper className={classes.menu}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                                            <MenuItem className={classes.menuItem} onClick={handleClose}>{t("app:menu:home")}</MenuItem>
                                            <MenuItem className={classes.menuItem} onClick={handleClose}>{t("app:menu:sendFiles")}</MenuItem>
                                            <MenuItem className={classes.menuItem} onClick={handleClose}>{t("app:menu:aboutUs")}</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </Grid>
            </Grid>
        </Toolbar>
    );
};

export default React.memo(Header);