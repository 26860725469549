import * as React from "react";

import {Button, CssBaseline, makeStyles, Theme, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Header} from "./components";
import {configuration} from "../configuration";



const useStyles = makeStyles((theme: Theme) => ({
    appFrame: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "flex-end",
    },
    mainContent: {
        marginTop: configuration.layout.toolbarHeight,
        height: "50vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: theme.spacing(4),
    },
    infoContainer: {
        position: "absolute",
        top: 0,
        width: "100vw",
        height: "30vh",
        background: theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    infoTitle: {
        paddingBottom: theme.spacing(1),
        color: theme.palette.common.white,
    }
}));

interface AppProps {

}

const App = (props: AppProps) => {

    const classes = useStyles();

    const {t,} = useTranslation();

    return (
        <div className={classes.appFrame}>
            <CssBaseline/>

            <Header />

            <div className={classes.infoContainer}>
                <Typography variant="h5" className={classes.infoTitle}>Drs. V.J. Kip</Typography>
                <Typography variant="body1" className={classes.infoTitle}>Huisartsenpraktijk De Brinck, Monster</Typography>
            </div>

            <main className={classes.mainContent}>
                <div>
                    primary text line 1<br/>
                    primary text line 2<br/>
                    <br/>
                    secondary text line 1<br/>
                    secondary text line 2<br/>
                    <br/>
                    <label htmlFor="upload-photo">
                        <input style={{display: "none"}}
                               id="upload-photo"
                               name="upload-photo"
                               type="file"
                               multiple={true}/>

                        <Button color="primary" variant="text" component="span">
                            {t("app:selectFiles")}
                        </Button>
                    </label>
                </div>

                <div>
                    <Button color="primary" variant="contained" component="span">
                        {t("app:upload")}
                    </Button>
                </div>
            </main>
        </div>
    );
};

export default React.memo(App);
