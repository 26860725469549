import * as React from "react";

import {Icon, IconProps, makeStyles} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
    icon: {
        display: "flex",
        alignItems: "center",
    }
});

const StyledIcon = (props: React.PropsWithoutRef<IconProps>, ref: React.Ref<HTMLSpanElement>) => {

    const classes = useStyles();

    const {className, ...otherProps} = props;

    return (
        <Icon className={classNames(classes.icon, className)} ref={ref} {...otherProps} />
    )
};

export default React.memo(React.forwardRef(StyledIcon));