import * as React from "react";
import * as ReactDOM from "react-dom";

import {BrowserRouter, Switch, Route} from "react-router-dom";

// Localization.
import "./i18n";

// Theming.
import {ThemeProvider} from "@material-ui/styles";
import {theme} from "./theme";

import "@mdi/font/css/materialdesignicons.css";

import App from "./ui/App";


ReactDOM.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Switch>
                <Route path="/" component={App}/>
            </Switch>
        </BrowserRouter>
    </ThemeProvider>,
    document.getElementById("root")
);
