import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import en_GB_locale from "./locales/en_GB.json";
import nl_NL_locale from "./locales/nl_NL.json";

i18next
    .use(initReactI18next)
    .init({
        debug: false,
        lng: "nl_NL",
        fallbackLng: "en_GB",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            "en_GB": en_GB_locale,
            "nl_NL": nl_NL_locale,
        }
    })
    .catch((error: Error) => console.log("Unable to initialize i18n: ", error.message));