import {createMuiTheme, Theme} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";

export const theme: Theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "0.9em",
            },
        },
        MuiList: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            },
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        MuiListItem: {
            container: {
                listStyle: "none",
            },
        },
        MuiButton: {
            containedPrimary: {
                color: grey["50"],
            },
        },
    },
    palette: {
        primary: {
            main: "#81ccc8",
        },
        secondary: {
            main: "#e2c02f"
        },
    },
});