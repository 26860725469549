const defaultConfiguration = {
    applicationName: "genesis",
    layout: {
        toolbarHeight: 65,
    },
    version: require("../package.json").version,
};

const stagingConfiguration = {
    genesis: {
        endpoint: "",
    }
};

const productionConfiguration = {
    genesis: {
        endpoint: "",
    }
};

export const configuration = (process.env.REACT_APP_BRANCH === "master")
    ? {...defaultConfiguration, ...productionConfiguration}
    : {...defaultConfiguration, ...stagingConfiguration};
